<template>
  <div class="h-screen flex flex-col items-center justify-center">
    <template v-if="order">
      <div v-if="reminder" class="text-red-500 font-semibold text-xl mb-4 px-4">
        {{ reminder }}
      </div>
      <div class="text-gray-500 text-sm">你的取餐号</div>
      <div class="font-bold text-3xl my-6">{{ order.quan_id }}</div>
      <van-button type="danger" @click="checkDetail">查看订单详情</van-button>
    </template>
    <div v-if="err">{{ err }}</div>
  </div>
</template>

<script>
import { getOrder, getReminder } from '@/api'
export default {
  data() {
    return {
      order: null,
      err: '',
      reminder: ''
    }
  },
  methods: {
    checkDetail() {
      parent.postMessage(
        JSON.stringify({
          action: 'jumpOut',
          jumpOutUrl: `${location.origin}/#/order?sale_id=${this.order.sale_id}`
        }),
        'https://payapp.weixin.qq.com'
      )
    }
  },
  created() {
    getReminder().then(data => {
      this.reminder = data[0].memo
    })
    getOrder({
      payid: this.$route.query.out_trade_no
    })
      .then(res => {
        this.order = res.SaleMain
      })
      .catch(err => {
        this.err = JSON.stringify(err)
      })
    let mchData = {
      action: 'onIframeReady',
      displayStyle: 'SHOW_CUSTOM_PAGE'
    }
    let postData = JSON.stringify(mchData)
    parent.postMessage(postData, 'https://payapp.weixin.qq.com')
  }
}
</script>

<style>
</style>